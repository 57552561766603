import React from 'react';
import PropTypes from 'prop-types';

import { H2 } from '../../styles/commonStyles';
import Card from '../Card/Card';
import { PostType } from '../../types/common';

import { CardListContainer, CardListWrapper } from './CardList.style';

const propTypes = {
  cards: PropTypes.arrayOf(PostType),
  heading: PropTypes.string,
};

const defaultProps = {
  cards: [],
  heading: null,
};

const CardList = ({ cards, heading }) => (
  <CardListContainer>
    {heading && <H2 theme="dark">{heading}</H2>}
    <CardListWrapper>
      {cards.map(card => (
        <Card card={card} key={card.id} />
      ))}
    </CardListWrapper>
  </CardListContainer>
);

CardList.propTypes = propTypes;
CardList.defaultProps = defaultProps;

export default CardList;
