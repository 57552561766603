import React from 'react';

import { PostType } from '../../types/common';

import {
  CardWrapper,
  Link,
  CardContent,
  DateAuthorHeader,
  Date,
  Author,
  StyledHeading,
  StyledParagraph,
} from './Card.style';

const propTypes = {
  card: PostType.isRequired,
};

const Card = ({ card }) => (
  <CardWrapper>
    <Link href={card.url}>
      <CardContent>
        <StyledHeading>{card.title}</StyledHeading>
        <DateAuthorHeader>
          <Date>{card.published.substring(0, 10)}</Date>
          <Author>{card.author}</Author>
        </DateAuthorHeader>
        <StyledParagraph theme="dark">{card.description}</StyledParagraph>
      </CardContent>
    </Link>
  </CardWrapper>
);

Card.propTypes = propTypes;

export default Card;
