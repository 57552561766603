import styled from 'styled-components';

import { breakpoints } from '../../styles/variables';

export const CardListContainer = styled.div`
  display: flex;
  padding: 2rem;
  justify-content: center;
`;

export const CardListWrapper = styled.div`
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${breakpoints.tabletPortrait} {
    width: 80%;
  }

  @media ${breakpoints.desktop} {
    width: 60%;
  }
`;
