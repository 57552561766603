import styled from 'styled-components';

import { colors, breakpoints } from '../../styles/variables';
import { Paragraph, H6 } from '../../styles/commonStyles';

export const CardWrapper = styled.div`
  display: flex;
  flex: 0 1 1;
  background-color: white;
  margin-bottom: 2rem;
  color: ${colors.darkShade};
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2rem;
  border: 1px solid ${colors.lightShade};
`;

export const Link = styled.a`
  width: 100%;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  letter-spacing: 0.1rem;
`;

export const CardImage = styled.div`
  display: block;
  border: 0;
  height: 20rem;
  background-size: cover;
  background-position: center;
  background-image: url('${({ image }) => image}')
`;

export const DateAuthorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.darkAccent};
  margin-top: 0.5rem;
`;

export const MiddleSection = styled.div`
  display: flex;
`;

export const Date = styled.span`
  font-size: 1.2rem;
`;

export const Author = styled.span`
  font-size: 1.2rem;
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: 1.4rem;
  color: ${colors.darkShade};
  letter-spacing: 0.1rem;

  @media ${breakpoints.phone} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.desktop} {
    font-size: 1.6rem;
  }

  @media ${breakpoints.desktopL} {
    font-size: 1.6rem;
  }
`;

export const StyledHeading = styled(H6)`
  letter-spacing: 0.2rem;
  text-align: left;
  margin: 0;
  color: ${colors.brandColor};
  font-size: 2rem;
`;
