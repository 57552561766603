import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PostType } from '../types/common';
import SEO from '../components/Seo/Seo';
import Hero from '../components/Hero/Hero';
import CardList from '../components/CardList/CardList';

const propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.arrayOf(PostType),
  }).isRequired,
};

const BlogPage = ({ pageContext }) => {
  const { t } = useTranslation('translations');
  const { posts } = pageContext;

  return (
    <>
      <SEO title={t('pages.blog.pageTitle')} />
      <Hero
        heading={t('pages.blog.heading')}
        paragraph={t('pages.blog.paragraph')}
        picture="/img/blog.svg"
        fullHeight={false}
        mirrored
        first
      />
      <CardList cards={posts} />
    </>
  );
};

BlogPage.propTypes = propTypes;

export default BlogPage;
